import { MatSnackBarConfig } from '@angular/material/snack-bar';
import {environment} from '../../environments/environment';

export function jwtTokenGetter(): string | null {
  return localStorage.getItem(environment.authTokenKey);
}

export function snackBarSuccessConfig(): any {
  const snackBarConfig = new MatSnackBarConfig();
  snackBarConfig.panelClass = 'snackbar-success';
  snackBarConfig.duration = 5000;
  snackBarConfig.verticalPosition = 'top';
  snackBarConfig.horizontalPosition = 'right';
  return snackBarConfig;
}

export function snackBarErrorConfig(): any {
  const snackBarConfig = new MatSnackBarConfig();
  snackBarConfig.panelClass = 'snackbar-error';
  snackBarConfig.duration = 10000;
  snackBarConfig.verticalPosition = 'top';
  snackBarConfig.horizontalPosition = 'right';
  return snackBarConfig;
}

export function snackBarWarningConfig(): any {
  const snackBarConfig = new MatSnackBarConfig();
  snackBarConfig.panelClass = 'snackbar-warning';
  snackBarConfig.duration = 5000;
  snackBarConfig.verticalPosition = 'top';
  snackBarConfig.horizontalPosition = 'right';
  return snackBarConfig;
}

export function snackBarDefaultConfig(): any {
  const snackBarConfig = new MatSnackBarConfig();
  snackBarConfig.duration = 5000;
  snackBarConfig.verticalPosition = 'top';
  snackBarConfig.horizontalPosition = 'right';
  return snackBarConfig;
}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
