import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from './services/auth/auth.service';
import {snackBarErrorConfig, snackBarSuccessConfig} from './config/app';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./login.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loading = false;
  forgotPassForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.forgotPassForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.email,
        Validators.required
      ])]
    });
  }

  ngOnInit(): void { }

  /**
   * Request for new password
   */
  onSend(): void {
    this.loading = true;
    this.authService.sendPassResetInst(this.forgotPassForm.value).subscribe(
      (res: any) => {
        this.snackBar.open(res.message, 'Ok', snackBarSuccessConfig());
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(err.error.message, 'Ok', snackBarErrorConfig());
        this.loading = false;
      }
    );
  }

}
