import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpRequest
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private modal: MatDialog,
        private injector: Injector
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError((errorResponse: HttpErrorResponse) => {

            if (errorResponse.status === 500) {
                // Write errors to log

                // const dialogRef = this.modal.open(Error500Component, {
                //     data: { message: errorResponse.error.message }
                // });
                // const state: RouterState = this.router.routerState;
                // const snapshot: RouterStateSnapshot = state.snapshot;
                // this.router.navigate(['/error-500'], {
                //     queryParams: { returnUrl: snapshot.url }
                // });
            }

            if (errorResponse.status === 403) {
                // Write errors to log

                // const dialogRef = this.modal.open(UnauthorizedComponent, {
                //     data: { message: errorResponse.error.message }
                // });
            }

            if (errorResponse.status === 404) {
                // Write errors to log

                // const dialogRef = this.modal.open(NotFoundDialogComponent, {
                //     data: { message: errorResponse.error.message }
                // });
            }

            return throwError(errorResponse);
        }));

    }
}
