<div *ngIf="loading" class="loading">Loading&#8230;</div>
<div class="col-xs-12">
  <div class="row center-xs">
    <div class="col-xs-6">
      <div class="card card-1">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-5 start-xs">
              <img src="../assets/images/digital-wallet.png" class="img-responsive">
            </div>
            <div class="col-xs-7 start-xs">
              <h3>Account Overview</h3>
              <mat-divider></mat-divider>
<!--              <p class="sub-txt">Hello {{user.name}}!</p>-->
              <p class="sub-txt">
                <strong>{{dailyPayoutCount|number}}</strong> payouts today worth
                <strong>{{dailyPayoutSum|currency:'NGN':'symbol-narrow'}}</strong>
              </p>
              <p class="sub-txt">
                <strong>{{monthPayoutCount|number}}</strong> payouts this month worth
                <strong>{{monthPayoutSum|currency:'NGN':'symbol-narrow'}}</strong>
              </p>
              <p class="sub-txt"><strong>{{totalPayoutSum|number}}</strong> overall payouts to game winners</p>
              <p class="sub-txt">
                <strong>{{pendingCashoutRequestCount|number}}</strong> pending payments worth
                <strong>{{pendingCashoutRequestSum|currency:'NGN':'symbol-narrow'}}</strong>
              </p>
              <mat-divider></mat-divider>
              <h4 class="sub-txt"><strong>Available Balance:
                {{availableBalance|currency:'NGN':'symbol-narrow':'1.0-0'}}</strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-3 start-xs">
      <div class="card">
        <h3>Game Instances</h3>
        <div class="row">
          <div class="col-xs-8">
            <p>{{totalGameInstances|number}}</p>
          </div>
          <div class="col-xs-4">
            <mat-icon class="icon ball-icon">extension</mat-icon>
          </div>
        </div>
        <canvas baseChart class="chart"
                [datasets]="gameLineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="gamesLineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
        </canvas>
      </div>
    </div>
    <div class="col-xs-3 start-xs">
      <div class="card">
        <h3>Users</h3>
        <div class="row">
          <div class="col-xs-8">
            <p>{{totalUsers|number}}</p>
          </div>
          <div class="col-xs-4">
            <mat-icon class="icon user-icon">group</mat-icon>
          </div>
        </div>
        <canvas baseChart class="chart"
                [datasets]="usersLineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="usersLineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
        </canvas>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12 start-xs">
  <div class="card tx">
<!--    <h3>Deposits, Withdrawals & Game Instances</h3>-->
    <canvas baseChart class="chart"
            [datasets]="paymentLineChartData"
            [labels]="paymentChartLabels"
            [options]="paymentChartOptions"
            [colors]="paymentLineChartColors"
            [legend]="true"
            [chartType]="areaChartType"
            [plugins]="lineChartPlugins">
    </canvas>
  </div>
</div>
<div class="col-xs-12">
  <div class="row center-xs">
    <div class="col-xs-7 start-xs">
      <mat-card class="mat-card">
        <h3>Latest Transactions</h3>
        <table mat-table [dataSource]="latestPayments" class="table" matSort>
          <ng-container matColumnDef="tx_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction ID</th>
            <td mat-cell *matCellDef="let tx">
              <a [routerLink]="['/payments/', tx.uuid, 'details']">{{tx.transaction_id}}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td mat-cell *matCellDef="let tx">
              {{tx.amount|currency:tx.currency:'symbol-narrow'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="paid_by">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid By</th>
            <td mat-cell *matCellDef="let tx">
              <a [routerLink]="['/users/', tx.user.uuid, 'details']">{{tx.user.name|titlecase}}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="payment_method">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Method</th>
            <td mat-cell *matCellDef="let tx">{{tx.data_dump.narration|titlecase}}</td>
          </ng-container>
          <ng-container matColumnDef="payment_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let tx">{{tx.payment_type|titlecase}}</td>
          </ng-container>
          <ng-container matColumnDef="when">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>When</th>
            <td mat-cell *matCellDef="let tx"> {{tx.created_at|amTimeAgo}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="paymentDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: paymentDisplayedColumns;"></tr>
        </table>
        <div class="row center-xs empty" *ngIf="latestPayments.data.length < 1">
          <div class="col-xs-12" *ngIf="!loading">
            <img src="assets/images/empty.svg" width="200">
            <p>No Payments Yet</p>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-xs-5 start-xs">
      <mat-card class="mat-card">
        <h3>Latest Users</h3>
        <table mat-table [dataSource]="latestUsers" class="table" matSort>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let user">{{user.email}}</td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let user">
              <a [routerLink]="['/users/', user.uuid, 'details']">{{user.name|titlecase}}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let user">{{user.phone}}</td>
          </ng-container>
          <ng-container matColumnDef="joined">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Joined</th>
            <td mat-cell *matCellDef="let user"> {{user.created_at | amTimeAgo}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="userDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: userDisplayedColumns;"></tr>
        </table>
      </mat-card>
    </div>
  </div>
</div>
