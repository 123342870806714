import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CashoutRequestService {

  apiUrl = environment.apiUrl + environment.adminPath;

  constructor(private http: HttpClient) { }

  /**
   * Get cashout requests
   *
   * @author G-Factor
   */
  getCashoutRequests(page: any, perPage: any, query: any): any {
    return this.http.get(this.apiUrl + `cashout-requests?page=${page + 1}&per_page=${perPage}&query=${query}`);
  }

  /**
   * Credit a users account
   *
   * @author G-Factor
   */
  creditUserAccount(id: number): any {
    return this.http.get(this.apiUrl + `cashout-request/${id}`);
  }

  /**
   * Get cashout request
   *
   * @author G-Factor
   */
  getCashoutRequest(id: number): any {
    return this.http.get(this.apiUrl + `cashout-request/${id}`);
  }
}
