import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-500',
  template: `
    <div class="container">
      <div class="col-xs-12">
        <div class="row center-xs">
          <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
          <div class="col-xs-12">
            <br>
            <img alt="Error 500" width="100" src="../assets/images/error.png">
            <h1>Internal Server Error!</h1>
            <h3>Something went wrong.<br>But don't worry, our engineers are working on it.</h3>
            <button mat-raised-button (click)="onClose()" color="primary">Close</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./error-500.component.scss']
})
export class Error500Component implements OnInit {

  loading = false;

  constructor(
    private dialog: MatDialogRef<Error500Component>
  ) { }

  ngOnInit(): void {}

  onClose(): void {
    this.dialog.close();
  }

}
