import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {UsersService} from './users/services/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentService} from './payment/services/payment.service';
import {GameInstanceService} from './game-instance/services/game-instance.service';
import {CashoutRequestService} from './cashout/services/cashout-request.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading = false;
  appName = environment.appName;

  public paymentLineChartData: ChartDataSets[] = [
    {
      label: 'Game Instances',
      fill: false,
      borderColor: 'rgba(24,145,208,.5)',
      // pointStyle: 'rectRot',
      data: [30, 34, 45, 29, 49, 53, 45]
    },
    {
      label: 'Withdrawal',
      fill: false,
      borderColor: 'red',
      // pointStyle: 'rectRot',
      data: [55, 39, 30, 43, 50, 33, 45]
    },
    {
      label: 'Deposits',
      fill: false,
      borderColor: 'green',
      // pointStyle: 'rectRot',
      data: [45, 59, 60, 53, 59, 73, 55]
    }
  ];
  public paymentChartLabels: Label[] = ['May 1', 'May 2', 'May 3', 'May 4', 'May 5', 'May 6', 'May 7'];
  public paymentChartOptions: (ChartOptions) = {
    showLines: true,
    scales: {
      yAxes: [
        { stacked: true }
      ],
      xAxes: [
        { stacked: true }
      ]
    },
    elements: {

    },
    responsive: true,
    legend: {
    }
  };
  public paymentLineChartColors: Color[] = [
    {
      borderColor: 'rgba(22,91,175,0.5)',
      // backgroundColor: 'rgba(255,255,255,1)',
    },
  ];

  public gameLineChartData: ChartDataSets[] = [
    {
      // fill: true,
      // borderColor: '#ffffff',
      // pointStyle: 'rectRot',
      data: [65, 59, 70, 71, 56, 55, 50]
    },
  ];
  public usersLineChartData: ChartDataSets[] = [
    {
      // fill: true,
      // borderColor: '#ffffff',
      // pointStyle: 'rectRot',
      data: [50, 69, 70, 71, 56, 60, 65]
    },
  ];
  public lineChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];
  public lineChartOptions: (ChartOptions) = {
    scales: {
      yAxes: [
        { display: false }
      ],
      xAxes: [
        { display: false }
      ]
    },
    elements: {
      line: {
        // fill: 'zero'
      },
      point: {
        radius: 1,
        hoverRadius: 10
      }
    },
    responsive: true,
    legend: {
      display: false
    }
  };
  public gamesLineChartColors: Color[] = [
    {
      borderColor: 'rgba(243,205,22,0.5)',
      backgroundColor: 'rgba(243,205,22,0.3)',
    },
  ];
  public usersLineChartColors: Color[] = [
    {
      borderColor: 'rgba(22,91,175,0.5)',
      backgroundColor: 'rgba(22,91,175,0.3)',
    },
  ];
  public lineChartLegend = false;
  public lineChartType: any = 'line';
  public areaChartType: any = 'bar';
  public lineChartPlugins = [];
  totalUsers = 0;
  totalTransactions = 0;
  totalGameInstances = 0;
  latestUsers = new MatTableDataSource<any>([]);
  latestPayments = new MatTableDataSource<any>([]);
  user = JSON.parse(localStorage.getItem(environment.authUser) as string);
  userDisplayedColumns: string[] = [
    'name',
    // 'email',
    'phone',
    'joined'
  ];
  paymentDisplayedColumns: string[] = [
    'tx_id',
    'amount',
    'paid_by',
    // 'payment_method',
    'payment_type',
    'when'
  ];
  dailyPayoutCount = 0;
  monthPayoutCount = 0;
  dailyPayoutSum = 0;
  monthPayoutSum = 0;
  totalPayoutSum = 0;
  totalCashoutRequests = 0;
  paidCashoutRequestCount = 0;
  pendingCashoutRequestSum = 0;
  pendingCashoutRequestCount = 0;
  availableBalance = 0;


  constructor(
    private title: Title,
    private userService: UsersService,
    private paymentService: PaymentService,
    private gameInstanceService: GameInstanceService,
    private cashoutRequestService: CashoutRequestService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Dashboard - ' + this.appName);
    this.getLatestUsers();
    this.getLatestPayments();
    this.getLatestGameInstances();
    this.getLatestCashoutRequests();
  }

  /**
   * Get latest users
   */
  getLatestUsers(): any {
    this.loading = true
    this.userService.getUsers(0, 8, '').subscribe((res: any) => {
      this.latestUsers.data = res.users;
      this.totalUsers = res.total;
      this.loading = false;
    });
  }

  /**
   * Get latest payments/transactions
   */
  getLatestPayments(): any {
    this.loading = true
    this.paymentService.getPayments(0, 8, '').subscribe((res: any) => {
      this.latestPayments.data = res.transactions;
      this.totalTransactions = res.total;
      this.loading = false;
    });
  }

  /**
   * Get latest game instances
   */
  getLatestGameInstances(): any {
    this.loading = true
    this.gameInstanceService.getGameInstances(0, 10, '').subscribe((res: any) => {
      this.totalGameInstances = res.total;
      this.loading = false;
    });
  }

  /**
   * Get latest cashout requests
   */
  getLatestCashoutRequests(): any {
    this.loading = true
    this.cashoutRequestService.getCashoutRequests(0, 10, '').subscribe((res: any) => {
      this.totalCashoutRequests = res.total;
      this.paidCashoutRequestCount = res.paid_cashout_requests;
      this.pendingCashoutRequestCount = res.pending_cashout_requests;
      this.pendingCashoutRequestSum = res.pending_payout_sum;
      this.totalPayoutSum = res.total_payout_sum;
      this.availableBalance = res.available_balance;
      this.loading = false;
    });
  }
}
