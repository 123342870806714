import { Directive, Input, Output, HostListener, EventEmitter } from '@angular/core';
import {AsyncPaymentOptions, Flutterwave, PaymentSuccessResponse} from 'flutterwave-angular-v3';
import {RaveOptions} from '../payment/models/rave-option';
import {environment} from '../../environments/environment';


@Directive({
  selector: '[appRavePayment]'
})

export class RavePaymentDirective {

  @Input() ravePublicKey: string;
  @Input() integrity_hash: string;
  @Input() tx_ref: string;
  @Input() payment_method: string;
  @Input() amount: number;
  @Input() currency: string;
  @Input() country: string;
  @Input() customer_name: string;
  @Input() customer_email: string;
  @Input() customer_phone: string;
  @Input() customer_firstname: string;
  @Input() customer_lastname: string;
  @Input() pay_button_text: string;
  @Input() custom_title: string;
  @Input() custom_description: string;
  @Input() redirect_url: string;
  @Input() custom_logo: string;
  @Input() meta: any;
  @Input() raveOptions: Partial<RaveOptions>;
  @Output() onclose: EventEmitter<void> = new EventEmitter();
  @Output() callback: EventEmitter<any> = new EventEmitter();

  paymentData: AsyncPaymentOptions;

  constructor(private flutterWave: Flutterwave) { }

  @HostListener('click')
  async buttonClick(): Promise<void> {
    await this.setValues();
    await this.makePayment();
  }

  setValues(): any {
    this.paymentData = {
      public_key: this.raveOptions ? this.raveOptions.ravePublicKey : this.nullMethod(),
      tx_ref: this.raveOptions ? this.raveOptions.tx_ref : this.nullMethod(),
      amount: this.raveOptions ? this.raveOptions.amount : this.nullMethod(),
      currency: this.raveOptions ? this.raveOptions.currency : this.nullMethod(),
      payment_options: 'card,ussd',
      meta: this.meta,
      customer: {
        name: this.raveOptions ? this.raveOptions.fullname : this.nullMethod(),
        email: this.raveOptions ? this.raveOptions.customer_email : this.nullMethod(),
        phone: this.raveOptions ? this.raveOptions.customer_phone : this.nullMethod()
      },
      customizations: {
        title: environment.appName,
        description: this.raveOptions ? this.raveOptions.custom_description : this.nullMethod(),
        logo: environment.appLogoUrl
      }
    };
  }

  validateOptions(): any {
    if (!this.raveOptions.ravePublicKey) {
      return console.error('Merchant public key is required');
    }
    if (!(this.raveOptions.customer_email || this.raveOptions.customer_phone)) {
      return console.error('Customer email or phone number is required');
    }
    if (!this.raveOptions.tx_ref) {
      return console.error('A unique transaction reference is required');
    }
    if (!this.raveOptions.amount) {
      return console.error('Amount to charge is required');
    }
    if (!this.callback.observers.length) {
      return console.error('You should attach to callback to verify your transaction');
    }
    if (this.onclose.observers.length) {
      this.raveOptions.onclose = () => this.onclose.emit();
    }
    this.raveOptions.callback = res => this.callback.emit(res);
    return true;
  }

  validateInput(): any {
    if (!this.ravePublicKey) {
      return console.error('Merchant public key is required');
    }
    if (!(this.customer_email || this.customer_phone)) {
      return console.error('Customer email or phone number is required');
    }
    if (!this.tx_ref) {
      return console.error('A unique transaction reference is required');
    }
    if (!this.amount) {
      return console.error('Amount to charge is required');
    }
    if (!this.callback) {
      return console.error('You should attach to callback to verify your transaction');
    }
    return true;
  }

  makePayment(): any {
    if (this.raveOptions && Object.keys(this.raveOptions).length > 3) {
      this.flutterWave.asyncInlinePay(this.paymentData).then((response) => {
        this.makePaymentCallback(response);
        this.flutterWave.closePaymentModal(5);
      });
    } else {
      if (this.validateInput()) {
        console.log('Invalid payment parameters');
      }
    }
  }

  makePaymentCallback(response: PaymentSuccessResponse|any): any {
    this.callback.emit(response);
    console.log('Payment callback', response);
  }

  closedPaymentModal(): void {
    this.onclose.emit();
  }

  nullMethod(): any {
    return null;
  }
}
