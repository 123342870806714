import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-error-404',
  template: `
    <div class="main-content">
      <div class="col-xs-12">
        <div class="row center-xs">
          <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
          <div class="col-xs-12 col-sm-6 col-md-4 center-sm">
            <img class="img-responsive" src="../assets/images/404.png" alt="Error 404. Page Not Found">
            <h1>Page Not Found</h1>
            <p>We can't find the page you're looking for.<br>You can return to the previous page or visit our homepage.</p>
            <a mat-raised-button routerLink="/dashboard">Return Home</a>&nbsp;&nbsp;
            <button mat-raised-button (click)="location.back()">Previous Page</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./error-404.component.scss']
})
export class Error404Component implements OnInit {

  loading = false;

  constructor(
    public location: Location
  ) { }

  ngOnInit(): void {}

}
