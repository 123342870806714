<div class="container">
  <div class="col-xs-12">
    <div class="row center-xs">
      <div class="col-xs-10 col-sm-8 col-md-6 col-lg-4 box">
        <img src="assets/images/i-sabi-logo-white.png" class="img-responsive">
        <div class="content">
          <h4 class="title">Forgot Password? Enter your email address below</h4>
          <form [formGroup]="forgotPassForm" (ngSubmit)="onSend()" [style.fontSize.px]="13">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email">
            </mat-form-field>
            <button *ngIf="!loading" type="submit" class="full-width" mat-raised-button color="primary"
                    [disabled]="!forgotPassForm.valid">Send</button>
            <button *ngIf="loading" mat-raised-button class="btn full-width" color="primary" disabled>
              Please wait...
              <mat-spinner class="loading-spinner" color="primary" mode="indeterminate"></mat-spinner>
            </button>
          </form>
          <div class="b-login">
            <a routerLink="/login">Back to login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
