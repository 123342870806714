import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpRequest,
  HttpClient
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TokenRefreshInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError((errorResponse: HttpErrorResponse) => {

      if (errorResponse.status === 401) {
        const http = this.injector.get(HttpClient);
        return http.post(environment.apiUrl + 'auth/refresh-token', {}).pipe(mergeMap(
          (res: any) => {
            localStorage.setItem(environment.authTokenKey, JSON.stringify(res.access_token));
            localStorage.setItem(environment.authRefreshTokenKey, JSON.stringify(res.refresh_token));
            const cloneRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${res.token}`
              }
            });

            return next.handle(cloneRequest);
          }
        ));
      }

      return throwError(errorResponse);
    }));

  }
}
