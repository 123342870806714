import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DefaultLayoutComponent} from './layouts/default-layout/default-layout.component';
import {HomeComponent} from './home.component';
import {AuthGuardService} from './services/auth/auth-guard.service';
import {LoginComponent} from './login.component';
import {ForgotPasswordComponent} from './forgot-password.component';
import {Error404Component} from './error-404.component';
import {Error500Component} from './error-500.component';
import {UnauthorizedComponent} from './unauthorized.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        data: {title: 'Dashboard'},
        component: HomeComponent
      }
    ]
  },
  {
    path: 'game-instances',
    canActivate: [AuthGuardService],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./game-instance/game-instance.module').then(m => m.GameInstanceModule)
      }
    ]
  },
  {
    path: 'players',
    canActivate: [AuthGuardService],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      }
    ]
  },
  {
    path: 'game-center',
    canActivate: [AuthGuardService],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./game-center/game-center.module').then(m => m.GameCenterModule)
      }
    ]
  },
  {
    path: 'payments',
    canActivate: [AuthGuardService],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
      }
    ]
  },
  {
    path: 'cashouts',
    canActivate: [AuthGuardService],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./cashout/cashout.module').then(m => m.CashoutModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'error-500',
    component: Error500Component
  },
  {
    path: 'error-404',
    component: Error404Component
  },
  {
    path: '**',
    redirectTo: 'error-404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
