<mat-drawer-container class="container" autosize>
  <mat-drawer class="sidenav" #drawer
              [mode]="mobileQuery.matches ? 'over' : 'side'"
              [opened]="!mobileQuery.matches">
    <mat-sidenav mode="side" opened class="sidenav">
      <mat-list class="list">
        <mat-list-item class="app-name">
          <img src="assets/images/i-sabi-logo-white.png" alt="{{appName}}">
        </mat-list-item>
      </mat-list>
      <a mat-button color="accent" class="btn" routerLink="/dashboard" routerLinkActive="active-link">
        <mat-icon class="icon">dashboard</mat-icon>
        Dashboard
      </a>
      <a mat-button color="accent" class="btn" routerLink="/game-instances" routerLinkActive="active-link">
        <mat-icon class="icon">extension</mat-icon>
        Game Instances
      </a>
      <a mat-button color="accent" class="btn" routerLink="/game-center/questions" routerLinkActive="active-link">
        <mat-icon class="icon">question_answer</mat-icon>
        Question & Answers
      </a>
      <a mat-button color="accent" class="btn" routerLink="/game-center/categories" routerLinkActive="active-link">
        <mat-icon class="icon">view_list</mat-icon>
        Question Categories
      </a>
      <a mat-button color="accent" class="btn" routerLink="/game-center/difficulty-levels" routerLinkActive="active-link">
        <mat-icon class="icon">format_list_numbered</mat-icon>
        Difficulty Levels
      </a>
      <a mat-button color="accent" class="btn" routerLink="/players" routerLinkActive="active-link">
        <mat-icon class="icon">group</mat-icon>
        Player Profiles
      </a>
<!--      <a mat-button color="accent" class="btn" routerLink="/winnings" routerLinkActive="active-link">-->
<!--        <mat-icon class="icon">emoji_events</mat-icon>-->
<!--        Winnings-->
<!--      </a>-->
      <a mat-button color="accent" class="btn" routerLink="/payments" routerLinkActive="active-link">
        <mat-icon class="icon">account_balance</mat-icon>
        Payments
      </a>
      <a mat-button color="accent" class="btn" routerLink="/cashouts" routerLinkActive="active-link">
        <mat-icon class="icon">price_change</mat-icon>
        Cashout Requests
      </a>
      <a mat-button color="accent" class="btn" routerLink="/shield" routerLinkActive="active-link">
        <mat-icon class="icon">badge</mat-icon>
        Security
      </a>
      <a mat-button color="accent" class="btn" routerLink="/settings" routerLinkActive="active-link">
        <mat-icon class="icon">settings</mat-icon>
        Settings
      </a>
    </mat-sidenav>
  </mat-drawer>
  <mat-drawer-content>
    <div class="col-xs-12 top-menu">
      <div class="row">
        <div class="col-xs-6 start-xs">
          <button mat-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
<!--          <p class="page-title">{{pageTitle}}</p>-->
        </div>
<!--        <div class="col-xs-4 start-xs">-->
<!--          <p class="page-title">Internet connection status:-->
<!--            <span [ngClass]="{'success': globalService.isConnected, 'error': !globalService.isConnected}">-->
<!--              {{globalService.internetConnectionStatus}}-->
<!--            </span>-->
<!--          </p>-->
<!--        </div>-->
        <div class="col-xs-6 end-xs">
          <p>
            <span class="avatar">{{user.name[0]}}{{user.name[1]}}</span>
            <span class="name">{{user.name}}</span>
            <button mat-icon-button (click)="onLogout()" color="warn" matTooltip="Logout">
              <mat-icon>power_settings_new</mat-icon>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="col-xs-12 main-content">
      <div class="page-title">{{pageTitle}}</div>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

