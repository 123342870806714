import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-dialog',
  template: `
    <div mat-dialog-content>
      <p>Are you sure about this?</p>
    </div>
    <div mat-dialog-actions class="center-xs">
      <button mat-button color="primary" (click)="dialogRef.close()">NO</button>
      <button mat-button color="warn" (click)="onConfirmDelete()">YES</button>
    </div>
  `,
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>
  ) { }

  onConfirmDelete(): void {
    this.dialogRef.close(true);
  }

}
