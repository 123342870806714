import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  apiUrl = environment.apiUrl + environment.adminPath;

  constructor(private http: HttpClient) { }

  /**
   * Get payments
   *
   * @author G-Factor
   */
  getPayments(page: any, perPage: any, query: any): any {
    return this.http.get(this.apiUrl + `transactions?page=${page + 1}&per_page=${perPage}&query=${query}`);
  }

  /**
   * Verify event payment made by rave
   *
   * @param paymentDetails
   */
  verifyRaveDeposit(paymentDetails: any): any {
    return this.http.post(this.apiUrl + 'verify-rave-deposit', paymentDetails);
  }
}
