import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {HttpErrorResponse} from '@angular/common/http';
import {snackBarErrorConfig} from '../../config/app';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  imageFile = '';
  submitBtn = false;
  @Input() image;
  @Output() imageUploaded: EventEmitter<any> = new EventEmitter();

  constructor(
    private snackBar: MatSnackBar,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Set file to upload via form group
   * @param event file selection event
   */
  onFileSelected(event): void {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.uploadImage();
    }
  }

  /**
   * Upload image
   */
  uploadImage(): any {
    this.submitBtn = true;
    if (this.imageFile) {
      this.globalService.uploadImage(this.imageFile).subscribe((res: any) => {
        this.imageUploaded.emit(res);
        this.submitBtn = false;
      }, (err: HttpErrorResponse) => {
        this.submitBtn = false;
        this.snackBar.open(err.error.message, 'Ok', snackBarErrorConfig());
      });
    }
  }

}
