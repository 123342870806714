import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../services/auth/auth.service';
import {MediaMatcher} from '@angular/cdk/layout';
import {GlobalService} from '../../services/global.service';
import {environment} from '../../../environments/environment';
import {ConfirmDeleteDialogComponent} from '../../shared/confirm-delete/confirm-delete-dialog.component';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {

  pageTitle: any;
  mobileQuery: MediaQueryList;
  appName = environment.appName;
  subs: Array<Subscription> = [];
  mobileQueryListener: () => void;
  user = JSON.parse(localStorage.getItem(environment.authUser) as string);

  constructor(
    private router: Router,
    public media: MediaMatcher,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    public globalService: GlobalService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.subs[0] = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.pageTitle = route.data.title;
      });
  }

  ngOnInit(): void {}

  /**
   * Log out the user
   */
  onLogout(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.authService.logout().subscribe(() => {
          localStorage.clear();
          this.router.navigateByUrl('/login');
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
