import { Injectable } from '@angular/core';
import {ConnectionService} from 'ng-connection-service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  isConnected = true;
  internetConnectionStatus = 'ONLINE';
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private connectionService: ConnectionService
  ) {
    this.isInternetOnline();
  }

  /**
   * Check & set status of internet connection
   */
  isInternetOnline(): void {
    this.connectionService.monitor().subscribe((isConnected: boolean) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.internetConnectionStatus = 'ONLINE';
      }
      else {
        this.internetConnectionStatus = 'OFFLINE';
      }
    });
  }

  /**
   * Upload image file
   * @param data image binary file
   */
  uploadImage(data): any {
    const formData = new FormData();
    formData.append('file', data);
    return this.http.post(this.apiUrl + `upload-image`, formData);
  }
}
