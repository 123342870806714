import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) { }

  /**
   * Get the user's details
   *
   * @author G-Factor
   */
  getUser(): any {
    return this.http.get(this.apiUrl + 'user');
  }

  /**
   * Register a user
   *
   * @author G-Factor
   */
  register(formData: any): any {
    return this.http.post(this.apiUrl + 'auth/register', formData);
  }

  /**
   * Request for token
   *
   * @author G-Factor
   */
  login(formData: any): any {
    return this.http.post(this.apiUrl + 'auth/login', formData);
  }

  /**
   * Log the user out
   *
   * @author G-Factor
   */
  logout(): any {
    return this.http.get(this.apiUrl + 'auth/logout');
  }

  /**
   * Send request to update users details
   *
   * @author G-Factor
   */
  updateUser(uuid: any, formData: any): any {
    return this.http.put(this.apiUrl + `user/${uuid}/profile`, formData);
  }

  /**
   * Change users password
   *
   * @author G-Factor
   */
  changePassword(formData: any): any {
    return this.http.post(this.apiUrl + 'auth/change-password', formData);
  }

  /**
   * Send password reset instruction
   *
   * @author G-Factor
   */
  sendPassResetInst(formData: any): any {
    return this.http.post(this.apiUrl + 'auth/forgot-password', formData);
  }

  /**
   * Reset the users password
   *
   * @author G-Factor
   */
  resetPassword(formData: any): any {
    return this.http.post(this.apiUrl + 'auth/reset-password', formData);
  }

  /**
   * Delete user account
   *
   * @author G-Factor
   */
  deleteAccount(): any {
    return this.http.delete(this.apiUrl + 'profile');
  }

  getToken(): any {
    return localStorage.getItem(environment.authTokenKey);
  }

  /**
   * Refresh token
   *
   * @author G-Factor
   */
  refreshToken(): any {
    return this.http.post(this.apiUrl + 'auth/refresh-token', this.refreshToken);
  }

  /**
   * Get token expiration date
   *
   * @author G-Factor
   */
  getTokenExpirationDate(): any {
    return this.jwtHelper.getTokenExpirationDate();
  }

  /**
   * Decode token
   *
   * @author G-Factor
   */
  decodeToken(): any {
    return this.jwtHelper.decodeToken();
  }

  /**
   * Check if user token is expired
   *
   * @author G-Factor
   */
  isTokenExpired(): any {
    return this.jwtHelper.isTokenExpired();
  }
}

