import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from './services/auth/auth.service';
import {environment} from '../environments/environment';
import {snackBarErrorConfig, snackBarSuccessConfig} from './config/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading = false;
  returnUrl: string;
  loginForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || '/dashboard';
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.email, Validators.required
      ])],
      password: ['', Validators.required]
    });
    if (!this.authService.isTokenExpired()) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  ngOnInit(): void { }

  /**
   * Login
   *
   * @author G-Factor
   */
  onLogin(): void {
    this.loading = true;
    this.authService.login(this.loginForm.value).subscribe(
      (res: any) => {
        if (res.user.is_admin ) {
          localStorage.setItem(environment.authTokenKey, JSON.stringify(res.access_token));
          localStorage.setItem(environment.authRefreshTokenKey, JSON.stringify(res.refresh_token));
          localStorage.setItem(environment.authUser, JSON.stringify(res.user));
          this.router.navigate([this.returnUrl]);
          this.snackBar.open('Welcome back ' + res.user.name, 'Ok', snackBarSuccessConfig());
        } else {
          this.loginForm.get('password')?.setValue(null);
          this.router.navigateByUrl('/login');
          this.snackBar.open('Access denied.', 'Ok', snackBarErrorConfig());
        }
        this.loading = false;
      },
      (res: HttpErrorResponse) => {
        this.loading = false;
        this.snackBar.open(res.error.message, 'Ok', snackBarErrorConfig());
      }
    );
  }

}
