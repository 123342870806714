import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DefaultLayoutComponent} from './layouts/default-layout/default-layout.component';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {jwtTokenGetter, DATE_FORMAT} from './config/app';
import {ErrorInterceptor} from './interceptor/error.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenRefreshInterceptor} from './interceptor/token-refresh.interceptor';
import {TokenInterceptor} from './interceptor/token.interceptor';
import {UnauthorizedComponent} from './unauthorized.component';
import {Error500Component} from './error-500.component';
import {HomeComponent} from './home.component';
import {ForgotPasswordComponent} from './forgot-password.component';
import {LoginComponent} from './login.component';
import {Error404Component} from './error-404.component';
import {SharedModule} from './shared/shared.module';
import {JwtModule} from '@auth0/angular-jwt';
import {environment} from '../environments/environment';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        throwNoTokenError: false,
        allowedDomains: [environment.baseUrl],
        disallowedRoutes: []
      }
    }),
    ChartsModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ForgotPasswordComponent,
    Error404Component,
    Error500Component,
    UnauthorizedComponent,
    DefaultLayoutComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenRefreshInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMAT
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
