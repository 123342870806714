import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  apiUrl = environment.apiUrl + environment.adminPath;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Get users
   */
  getUsers(page: any, perPage: any, query: any): any {
    return this.http.get(this.apiUrl + `users?page=${page + 1}&per_page=${perPage}&query=${query}`);
  }

  /**
   * Add a new user
   */
  addUser(formData: any): any {
    return this.http.post(this.apiUrl + 'users', formData);
  }

  /**
   * Update a user
   */
  updateUser(formData: any, id: number): any {
    return this.http.put(this.apiUrl + `user/${id}`, formData);
  }

  /**
   * Delete a user
   */
  deleteUser(id: number): any {
    return this.http.delete(this.apiUrl + `user/${id}`);
  }
}
