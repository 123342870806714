import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-unauthorized',
  template: `
    <div class="container">
      <div class="col-xs-12">
        <div class="row center-xs">
          <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
          <div class="col-xs-12">
            <br>
            <img alt="Unauthorized access" width="100" src="../assets/images/access_error.png">
            <h4>Unauthorized Operation!</h4>
            <p>{{ data.message }}</p>
            <button mat-button (click)="onClose()" color="primary">Close</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  loading = false;

  constructor(
    private dialog: MatDialogRef<UnauthorizedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {}

  onClose(): void {
    this.dialog.close();
  }

}
