import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GameInstanceService {

  apiUrl = environment.apiUrl + environment.adminPath;

  constructor(private http: HttpClient) { }

  /**
   * Get game instances
   *
   * @author G-Factor
   */
  getGameInstances(page: any, perPage: any, query: any): any {
    return this.http.get(this.apiUrl + `game-instances?page=${page + 1}&per_page=${perPage}&query=${query}`);
  }

  /**
   * Get a game instance
   *
   * @author G-Factor
   */
  getGameInstance(id: number) {
    return this.http.get(this.apiUrl + `game-instance/${id}`);
  }
}
