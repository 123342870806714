// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'iSabi Ball',
  appLogoUrl: '',
  environment: 'DEV',
  baseUrl: 'https://api.isabiball.com/',
  apiUrl: 'https://api.isabiball.com/v1/',
  authTokenKey: '__isabi_adm_auth_tok',
  authRefreshTokenKey: '__isabi_adm_auth_ref_tok',
  authUser: '__isabi_adm_auth_user',
  adminPath: 'admin/',
  ravePubKey: 'FLWPUBK_TEST-8ef93a6b664ee95e372c7b5d25b4c72d-X',
  depositPaymentType: 'Deposit',
  withdrawPaymentType: 'Withdrawal',
  defaultCurrencyCode: 'NGN'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
